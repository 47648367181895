import EventEmitter from 'events';
import {
    CInvalidFeedback,
    CValidFeedback
} from '@coreui/react'

const UpdateBearer = (history, response) => {
    try{
        let bearer = response.headers.authorization.replace("Bearer", "").trim();
        if(bearer){
            localStorage.setItem('accessToken', bearer);
        }else{
            ErrorAction(history, response.status);
        }
    }catch(e){
        ErrorAction(history, response.status);
    }
};

const ErrorAction = (history, status) => {
    switch(status) {
        case 401:
            localStorage.removeItem('accessToken');
            history.push("/login");
            break;
    }
};

const Bus = new EventEmitter();

const errorText = (content) => {
    if(content) return <CInvalidFeedback>{ content }</CInvalidFeedback>;
};

const successText = (content) => {
    if(content) return <CValidFeedback>{ content }</CValidFeedback>;
};

const checkPermission = (permission) => {
    try {
        const authAccess = JSON.parse(localStorage.getItem('authAccess'));
        return (authAccess.is_superuser || authAccess.permissions.includes(permission)) ? true : false;
    } catch(e) {
        return false;
    }
};

const checkPermissions = (permissions) => {
    for (let i = 0; i < permissions.length; i++) {
        if(checkPermission(permissions[i])){
            return true;
        }
    }
    return false;
};

const isSuperAdmin = () => {
    try {
        const authAccess = JSON.parse(localStorage.getItem('authAccess'));
        return (authAccess.is_superuser) ? true : false;
    } catch(e) {
        return false;
    }
};

const tryParseJSONObject = (jsonString) => {
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};

const PageToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
};

export {
    errorText,
    successText,
    UpdateBearer,
    ErrorAction,
    Bus,
    checkPermissions,
    checkPermission,
    isSuperAdmin,
    tryParseJSONObject,
    PageToTop
}